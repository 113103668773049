<template>
  <b-overlay :show="isProcessing == 'fetching-list'">
    <b-tabs>
      <b-tab :title="$t('ai-ide-module.ai-ide')">
        <manage-web-page
          ref="manageWebPage"
          :pages="pageLists"
          :activate-page="activatePage"
          @onSelectPage="onSelectPage"
          @onRefetch="getWebPageList"
        />
        <div v-if="activatePage"
             class="tasks"
        >
          <div class="row">
            <div class="col-4">
              <b-input-group>
                <b-form-select v-model="taskId"
                               :options="tasks"
                               text-field="name"
                               value-field="id"
                />
                <b-input-group-append v-if="taskId">
                  <b-button
                    variant="flat-success"
                    class="btn-icon border"
                    @click="editTask"
                  >
                    <feather-icon icon="EditIcon" />
                  </b-button>
                  <b-button
                    variant="flat-danger"
                    class="btn-icon border"
                    @click="taskToDelete = taskId"
                  >
                    <feather-icon icon="TrashIcon" />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </div>
            <div class="col-6">
              <b-button variant="outline-success"
                        :disabled="!!isProcessing"
                        @click="showCreateTaskModal = true; newTaskInfo = { name: '', description: '' }"
              >
                <feather-icon icon="PlusIcon" /> {{ $t('ai-ide-module.add-new-task') }}
              </b-button>
              <b-button variant="outline-primary"
                        :disabled="!!isProcessing"
                        class="ml-2"
                        @click="showCreateProcessModal = true;newProcessName = ''"
              >
                <feather-icon icon="PlusIcon" /> {{ $t('ai-ide-module.add-new-step') }}
              </b-button>
            </div>
          </div>
          <div v-for="itemTask,index of tasks.filter(item => item.id == taskId)"
               :key="index"
               class="mt-2"
          >
            <div v-for="item, pi of itemTask.processes"
                 :key="pi"
                 class="process px-3 border"
            >
              <h4>
                {{ item.name }} <b-button
                  variant="flat-danger"
                  class="btn-icon rounded-circle"
                  @click="processToDelete = item"
                >
                  <feather-icon icon="TrashIcon" />
                </b-button>
                <b-button
                  variant="flat-success"
                  class="btn-icon rounded-circle"
                  @click="editProcess(item)"
                >
                  <feather-icon icon="EditIcon" />
                </b-button>
                <b-row>
                  <b-col md="1">
                    <b-form-checkbox v-model="item.enabled"
                                     switch
                                     @change="bool => enableStep(bool, item, itemTask)"
                    />
                  </b-col>
                  <b-col md="11">
                    <p class="text-muted"
                       style="font-size:13px; font-style: italic;"
                    >
                      <feather-icon icon="InfoIcon" />
                      {{ $t('ai-ide-module.info') }}
                    </p>
                  </b-col>
                </b-row>
              </h4>
              <div class="row">
                <div class="col-8">
                  <template v-for="model,mi of models">
                    <component :is="model.component"
                               v-show="item.model_type == model.value"
                               :key="mi"
                               :model-form="item"
                    />
                  </template>
                  <b-tabs pills>
                    <b-tab :title="$t('ai-ide-module.json')">
                      <ResponsePage :model-form="item" />
                    </b-tab>
                    <b-tab :title="$t('ai-ide-module.chat')">
                      <response-chat :messages="messageChats"
                                     :is-bot-typing="!!isProcessing"
                                     @onMessage="msg => onSendMessage(item, msg)"
                      />
                    </b-tab>
                    <b-tab :title="$t('ai-ide-module.image-generation')">
                      <StableDiffusion />
                    </b-tab>
                  </b-tabs>
                </div>
                <div class="col-4">
                  <model-setting
                    :model-form="item"
                  >
                    <div class="mt-1">
                      <b-button variant="outline-primary"
                                :disabled="!!isProcessing"
                                @click="handleProcessUpdate(item, [])"
                      >
                        <b-spinner v-if="isProcessing === true"
                                   small
                        />
                        {{ $t('ai-ide-module.save-submit') }}
                      </b-button>
                      <b-button v-if="item.child_info"
                                variant="outline-primary"
                                class="ml-2"
                                :disabled="!!isProcessing"
                                @click="handleProcessUpdate(item, itemTask.processes)"
                      >
                        <b-spinner v-if="isProcessing === true"
                                   small
                        />  Save And Run All
                      </b-button>
                    </div>
                  </model-setting>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ConfirmDelete
          :show="!!processToDelete"
          :is-processing-delete="isDeleting"
          :on-delete="handleDelete"
          :title="$t('delete')"
          @close="processToDelete = null"
        />
        <ConfirmDelete
          :show="!!taskToDelete"
          :is-processing-delete="isDeleting"
          :on-delete="handleTaskDelete"
          :title="$t('delete')"
          @close="taskToDelete = null"
        />
        <!-- update or create process -->
        <b-modal v-if="showCreateProcessModal || !!processIdToEdit"
                 :title="processIdToEdit ? $t('ai-ide-module.update-process') : $t('ai-ide-module.new-process')"
                 :visible="true"
                 @close="showCreateProcessModal = false; processIdToEdit = null"
                 @hide="showCreateProcessModal = false; processIdToEdit = null"
        >
          <b-form-group :label="$t('ai-ide-module.process-name')">
            <b-form-input v-model="newProcessName"
                          :placeholder="$t('ai-ide-module.process-name')"
            />
          </b-form-group>
          <ChildProcess :process-id="processIdToEdit"
                        :form="childProcess"
                        :all-process="activeProcess"
          />
          <template #modal-footer>
            <b-button variant="outline-primary"
                      @click="createNewProcess"
            >
              <b-spinner v-if="isProcessing == 'new-one'"
                         small
              />  {{ processIdToEdit ? $t('actions.update') : $t('actions.save') }}
            </b-button>
          </template>
        </b-modal>
        <!-- update or create tasks -->
        <b-modal v-if="showCreateTaskModal || !!taskIdToEdit"
                 :title="taskIdToEdit ? $t('ai-ide-module.update-task') : $t('ai-ide-module.new-task')"
                 :visible="true"
                 @close="showCreateTaskModal = false; taskIdToEdit = null"
                 @hide="showCreateTaskModal = false; taskIdToEdit = null"
        >
          <b-form-group :label="$t('ai-ide-module.task-name')">
            <b-form-input v-model="newTaskInfo.name"
                          :placeholder="$t('ai-ide-module.task-name')"
                          :readonly="!!taskIdToEdit"
            />
          </b-form-group>
          <b-form-group :label="$t('ai-ide-module.task-description')">
            <b-form-textarea v-model="newTaskInfo.description"
                             :placeholder="$t('ai-ide-module.task-description')"
            />
          </b-form-group>
          <template #modal-footer>
            <b-button variant="outline-primary"
                      @click="createNewTask"
            >
              <b-spinner v-if="isProcessing == 'new-one'"
                         small
              />  {{ taskIdToEdit ? $t('actions.update') : $t('actions.save') }}
            </b-button>
          </template>
        </b-modal>
      </b-tab>
      <b-tab :title="$t('ai-ide-module.chat-ide')">
        <ChatIde />
      </b-tab>
    </b-tabs>
  </b-overlay>
</template>
<script>
import {
  GPT4, GPT4o, GPT4oMINI, GPT3_5, WRITE_SHIFT, NOUSRESEARCH_NOUS_CAPYBARA_7B, MISTRALAI_MISTRAL_7B_INSTRUCT, HUGGINGFCEH4_ZEPHYR_7B_BETA,
  MYTHO_MIST_7B, PSYFIGHTER_13B, PSYFIGHTER_13B_2, NOUS_HERMES_LLAMA2_13B, PHIND_CODELLAMA_34B, MISTRAL_8X7B_INSTRUCT, LLAMA_2_13B_CHAT,
  PALM_2_CHAT_BISON, PALM_2_CODECHAT_BISON, PALM_2_CHAT_BISON_32K, PALM_2_CODECHAT_BISON_32K, GEMINI_PRO, PPLX_70B_ONLINE, PPLX_7B_ONLINE,
  LLAMA_2_70B_CHAT, GPT3_5_TURBO_TRAINED, GPT_O1, GPT_O1_MINI,
} from '@/const/ai-models'
import {
  BButton, BSpinner, BOverlay, BFormSelect, BModal, BFormInput, BInputGroup, BInputGroupAppend, BFormTextarea, BFormGroup, BFormCheckbox, BRow, BCol, BTab, BTabs,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import ConfirmDelete from '@/views/common/components/DeleteModal.vue'
import ManageWebPage from './components/ManageWebPage.vue'
import WriteShiftProcess from './write-shift/index.vue'
import GPT4Process from './gpt-4/index.vue'
import ModelSetting from './components/ModelSettings.vue'
import ResponsePage from './components/ResponsePage.vue'
import ChatIde from './chat-ide/index.vue'
import ResponseChat from './components/ResponseChat.vue'
import StableDiffusion from './stable-diffusion/index.vue'
import ChildProcess from './components/ChildProcess.vue'

export default {
  components: {
    ResponseChat,
    ManageWebPage,
    WriteShiftProcess,
    StableDiffusion,
    ModelSetting,
    GPT4Process,
    BButton,
    BSpinner,
    ResponsePage,
    BOverlay,
    BFormSelect,
    BModal,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BFormTextarea,
    BFormGroup,
    ConfirmDelete,
    BFormCheckbox,
    ChatIde,
    BRow,
    BCol,
    BTab,
    BTabs,
    ChildProcess,
  },
  data() {
    return {
      tasks: [],
      activatePage: null,
      models: [
        {
          name: 'O1 Mini', value: GPT_O1_MINI, component: GPT4Process,
        },
        {
          name: 'O1', value: GPT_O1, component: GPT4Process,
        },
        {
          name: 'GPT4', value: GPT4, component: GPT4Process,
        },
        {
          name: 'GPT-4o', value: GPT4o, component: GPT4Process,
        },
        {
          name: 'GPT-4o-Mini', value: GPT4oMINI, component: GPT4Process,
        },
        {
          name: 'GPT3.5', value: GPT3_5, component: GPT4Process,
        },
        {
          name: 'GPT3.5 Turbo (Trained)', value: GPT3_5_TURBO_TRAINED, component: GPT4Process,
        },
        {
          name: 'Write Shift', value: WRITE_SHIFT, component: WriteShiftProcess,
        },
        {
          name: 'Nous Capybara 7B (Free)', value: NOUSRESEARCH_NOUS_CAPYBARA_7B, component: GPT4Process,
        },
        {
          name: 'Mistral 7B Instruct (Free)', value: MISTRALAI_MISTRAL_7B_INSTRUCT, component: GPT4Process,
        },
        {
          name: 'Hugging Face Zephyr 7B (Free)', value: HUGGINGFCEH4_ZEPHYR_7B_BETA, component: GPT4Process,
        },
        {
          name: 'MythoMist 7B (Free)', value: MYTHO_MIST_7B, component: GPT4Process,
        },
        {
          name: 'Psyfighter 13B', value: PSYFIGHTER_13B, component: GPT4Process,
        },
        {
          name: 'Psyfighter v2 13B', value: PSYFIGHTER_13B_2, component: GPT4Process,
        },
        {
          name: 'Nous Hermes 13B', value: NOUS_HERMES_LLAMA2_13B, component: GPT4Process,
        },
        {
          name: 'Phind CodeLlama 34B v2', value: PHIND_CODELLAMA_34B, component: GPT4Process,
        },
        {
          name: 'Mistral Mixtral 8x7B Instruct', value: MISTRAL_8X7B_INSTRUCT, component: GPT4Process,
        },
        {
          name: 'Meta Llama v2 13B Chat', value: LLAMA_2_13B_CHAT, component: GPT4Process,
        },
        {
          name: 'Google PaLM 2 Chat', value: PALM_2_CHAT_BISON, component: GPT4Process,
        },
        {
          name: 'Google PaLM 2 Code Chat', value: PALM_2_CODECHAT_BISON, component: GPT4Process,
        },
        {
          name: 'Google PaLM 2 Chat 32k', value: PALM_2_CHAT_BISON_32K, component: GPT4Process,
        },
        {
          name: 'Google PaLM 2 Code Chat 32k', value: PALM_2_CODECHAT_BISON_32K, component: GPT4Process,
        },
        {
          name: 'Google Gemini Pro', value: GEMINI_PRO, component: GPT4Process,
        },
        {
          name: 'Perplexity PPLX 70B Online', value: PPLX_70B_ONLINE, component: GPT4Process,
        },
        {
          name: 'Perplexity PPLX 7B Online', value: PPLX_7B_ONLINE, component: GPT4Process,
        },
        {
          name: 'Meta Llama v2 70B Chat', value: LLAMA_2_70B_CHAT, component: GPT4Process,
        },
      ],
      pageLists: [],
      isProcessing: false,
      processToDelete: null,
      taskToDelete: null,
      isDeleting: false,
      taskId: null,
      showCreateProcessModal: false,
      showCreateTaskModal: false,
      newTaskInfo: {
        description: '',
        name: '',
      },
      newProcessName: '',
      childProcess: {
        child_id: null,
        variables: [],
      },
      taskIdToEdit: null,
      processIdToEdit: null,
      messageChats: [],
    }
  },
  computed: {
    activeProcess() {
      return this.tasks.find(i => i.id === this.taskId)?.processes
    },
  },
  mounted() {
    this.getWebPageList()
  },
  methods: {
    onSendMessage(process, msg) {
      this.messageChats.push({
        role: 'user',
        content: msg,
      })
      this.handleProcessUpdate(process, [], true)
    },
    handleTaskDelete() {
      this.isDeleting = true
      useJwt.destroyAiIdeTask(this.taskToDelete).then(response => {
        this.showSuccessMessage(response)
        this.tasks = this.tasks.filter(t => t.id !== this.taskToDelete)
        this.taskId = this.tasks[0]?.id
        this.taskToDelete = null
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.isDeleting = false
      })
    },
    getWebPageList() {
      this.isProcessing = 'fetching-list'
      useJwt.showAiIdePage().then(response => {
        this.pageLists = response.data.data
        const first = this.pageLists[0]
        if (first) {
          this.onSelectPage(first)
        }
      }).finally(() => {
        this.isProcessing = false
      }).catch(error => {
        this.showErrorMessage(error)
      })
    },
    onSelectPage(page) {
      this.activatePage = page
      this.isProcessing = 'fetching-list'
      this.tasks = page.tasks
      this.taskId = page.tasks?.[0]?.id
      setTimeout(() => {
        this.isProcessing = false
      }, 500)
    },
    editTask() {
      // eslint-disable-next-line no-undef, eqeqeq
      const task = this.tasks.find(item => item.id == this.taskId)
      this.taskIdToEdit = task.id
      this.newTaskInfo = { ...task }
    },
    editProcess(process) {
      this.processIdToEdit = process.id
      this.newProcessName = process.name
      this.childProcess = {
        child_id: process.child_info.child_id,
        variables: process.child_info.variables,
      }
    },
    createNewTask() {
      this.isProcessing = 'new-one'
      const { newTaskInfo } = this
      newTaskInfo.taskId = this.taskIdToEdit
      useJwt.createAiIdeTask(this.activatePage.id, newTaskInfo).then(response => {
        if (this.taskIdToEdit) {
          const tasksLists = [...this.tasks]
          const index = tasksLists.findIndex(item => item.id === this.taskIdToEdit)
          tasksLists[index] = response.data.data
          this.tasks = tasksLists
        } else {
          this.tasks.push(response.data.data)
        }
        this.newTaskInfo = {
          name: '',
          description: '',
        }
        this.showCreateTaskModal = false
        this.taskIdToEdit = null
        this.showSuccessMessage(response)
      }).finally(() => {
        this.isProcessing = false
      }).catch(error => {
        this.showErrorMessage(error)
      })
    },
    createNewProcess() {
      this.isProcessing = 'new-one'
      const newProcess = { }
      newProcess.name = this.newProcessName
      newProcess.order_no = this.tasks.length + 1
      newProcess.processId = this.processIdToEdit
      newProcess.childProcess = this.childProcess
      useJwt.createAiIdeProcess(this.taskId, newProcess).then(response => {
        const currentTaskIndex = this.tasks.findIndex(item => item.id === this.taskId)
        if (this.processIdToEdit) {
          const taskIndex = this.tasks.findIndex(item => item.id === this.taskId)
          const processIndex = this.tasks[taskIndex].processes.findIndex(item => item.id === this.processIdToEdit)
          const { processes } = this.tasks[taskIndex]
          const oldResponse = processes[processIndex].response
          processes[processIndex] = { ...response.data.data, response: oldResponse }
          this.tasks[taskIndex].processes = processes
        } else {
          this.tasks[currentTaskIndex].processes.push(response.data.data)
        }
        this.newProcessName = ''
        this.showCreateProcessModal = false
        this.processIdToEdit = null
        this.showSuccessMessage(response)
        this.$forceUpdate()
      }).finally(() => {
        this.isProcessing = false
      }).catch(error => {
        this.showErrorMessage(error)
      })
    },
    handleDelete() {
      this.isDeleting = true
      useJwt.deleteAiIdeProcess(this.processToDelete.id).then(response => {
        this.showSuccessMessage(response)
        const taskIndex = this.tasks.findIndex(item => item.id === this.taskId)
        let { processes } = this.tasks[taskIndex]
        // eslint-disable-next-line eqeqeq
        processes = processes.filter(item => item.id != this.processToDelete.id)
        this.tasks[taskIndex].processes = processes
        this.$forceUpdate()
      }).finally(() => {
        this.isDeleting = false
        this.processToDelete = null
      })
    },
    automateChildProcess(childSteps, allProcess) {
      childSteps.forEach(item => {
        const child = allProcess.find(i => i.id === item.child_id)
        child.variables = item.variables
        this.$forceUpdate()
        this.handleProcessUpdate(child, allProcess)
      })
    },
    handleProcessUpdate(process, allProcess = [], isHistory = false) {
      this.isProcessing = true
      // eslint-disable-next-line no-param-reassign
      const taskIndex = this.tasks.findIndex(item => item.id === this.taskId)
      const processIndex = this.tasks[taskIndex].processes.findIndex(item => item.id === process.id)
      const { processes } = this.tasks[taskIndex]
      useJwt.updateAiIdeProcess(process.id, {
        ...process, is_history: isHistory, chat_history: this.messageChats, update_child: !!allProcess.length,
      }).then(response => {
        processes[processIndex] = { ...response.data.data, child_info: processes[processIndex]?.child_info || null }
        this.tasks[taskIndex].processes = processes
        // ? update child process
        if (response.data.data.chat_response) {
          this.messageChats.push({
            role: 'system',
            content: response.data.data.chat_response,
          })
        }
        this.showSuccessMessage(response)
        this.$forceUpdate()
        if (allProcess.length && response.data.data.childSteps) {
          setTimeout(() => {
            this.automateChildProcess(response.data.data.childSteps, allProcess)
          }, 100)
        }
      }).finally(() => {
        this.isProcessing = false
      }).catch(error => {
        processes[processIndex] = {
          ...processes[processIndex],
          response: {
            type: 'error',
            message: error,
          },
        }
        this.tasks[taskIndex].processes = processes
        this.showErrorMessage(error)
        this.$forceUpdate()
      })
    },
    enableStep(bool, item, itemTasks) {
      useJwt.enableDisableIdeProcess(item.id, {
        enabled: bool,
        task_id: item.task_id,
      }).then(response => {
        this.showSuccessMessage(response)
        const processes = itemTasks.processes.map(i => {
          // eslint-disable-next-line eqeqeq
          if (item.id != i.id) {
            // eslint-disable-next-line no-param-reassign
            i.enabled = false
          }
          return i
        })
        // eslint-disable-next-line no-param-reassign
        itemTasks.processes = processes
      })
    },
  },
}
</script>
